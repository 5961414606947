import { DragAndDropForm } from "./DragAndDropForm";
import { Title } from "./Primitives/Title";
import { SampleUrls } from "./SampleUrls";
import { UrlForm } from "./UrlForm";

export function NewFile() {
  return (
    <div className="w-2xl">
      <div className="mb-4 w-2xl">
        <UrlForm />
      </div>
      <DragAndDropForm />
    </div>
  );
}
